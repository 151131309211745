import { ReactNode, useEffect } from 'react';
import clsx from 'clsx';

import { Typography } from 'components/Typography';

import { useToggle } from 'hooks/useToggle';

import LoadingIcon from 'icons/LoadingIcon';

interface LoadingProps {
  className?: string;
  strokeDashoffset?: number;
  show: boolean;
  message?: string;
  delay?: number;
  children?: ReactNode;
  height?: number;
  width?: number;
  color?: string;
}

/**
 * TODO:
 * - Need to move this component to feature-components
 * - Remove the `react-spring` dependency to animate the spinner
 * - combine it with splash screen
 */
function Loading({ className, show, message = 'Loading', delay = 700, children, height, width, color }: LoadingProps) {
  const isDelay = !!(delay && delay > 0);
  const [loading, setLoading] = useToggle(isDelay ? false : true);

  useEffect(() => {
    let clearTimeout: number | undefined;

    if (show && isDelay) {
      clearTimeout = window.setTimeout(() => {
        setLoading(true);
      }, delay);
    }

    return () => {
      if (clearTimeout) {
        window.clearTimeout(clearTimeout);
      }
    };
  }, [show, isDelay]);

  if (show && loading) {
    return (
      <>
        <div className={clsx('relative flex h-full w-full flex-1 flex-col items-center justify-center', className)}>
          <LoadingIcon height={height} width={width} color={color} ariaLabel="loading" />
          <Typography variant="body1" component="p" className="pt-5">
            {message}
          </Typography>
        </div>
      </>
    );
  }

  return <>{children}</>;
}

export { Loading };
