import axios from 'axios';
import { create } from 'zustand';

interface AppConfigBase {
  configLoadingError: boolean;
  configIsLoaded: boolean;
  fetchConfig: () => void;
}
interface AppConfigNotLoaded extends AppConfigBase {
  configLoadingError: boolean;
  configIsLoaded: false;
  features: Record<never, never>;
}

interface AppConfigLoaded extends AppConfigBase {
  configLoadingError: boolean;
  configIsLoaded: true;
  features: {
    ENV: 'development' | 'staging' | 'production';
    MARKET: 'US' | 'AUS';
  };
}

type AppConfig = AppConfigNotLoaded | AppConfigLoaded;

export const useFeatureFlagState = create<AppConfig>((set) => ({
  configLoadingError: false,
  configIsLoaded: false,
  features: {},
  fetchConfig: async () => {
    await axios
      .get('/config.json')
      .then((res) => {
        set((state: AppConfig) => ({ features: res.data, configIsLoaded: true, configLoadingError: false }));
      })
      .catch((err) => {
        //  Set defaults
        set((state: AppConfig) => ({
          features: {
            ENV: 'production',
            MARKET: 'US',
          },
          configIsLoaded: true,
          configLoadingError: true,
        }));
      });
  },
}));
