import IconProps from './IconProps';

function ErrorSystemIllustration(props: IconProps) {
  const { ariaLabel, width = 174, height = 163 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 174 163" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label={ariaLabel}>
      <circle cx="88.5" cy="81.5" r="81.5" fill="#AED5F8" />
      <path
        d="M86.0683 55.3477H163.932C169.468 55.3477 174 59.9008 174 65.4653V119.341C174 124.905 169.468 129.458 163.932 129.458H157.599V144.445L140.599 129.458H86.0683C80.5322 129.458 76 124.905 76 119.341V65.4653C76 59.9008 80.5322 55.3477 86.0683 55.3477Z"
        fill="#4583FF"
      />
      <path
        d="M129.974 96.9694H124.132L123.211 77.3715H130.894L129.974 96.9694ZM123 103.074C123 102.103 123.379 101.304 124.145 100.683C124.907 100.059 125.858 99.7466 127 99.7466C128.142 99.7466 129.093 100.059 129.855 100.683C130.621 101.304 131 102.103 131 103.074C131 104.047 130.621 104.846 129.855 105.467C129.093 106.091 128.142 106.403 127 106.403C125.858 106.403 124.907 106.091 124.145 105.467C123.379 104.846 123 104.047 123 103.074Z"
        fill="white"
      />
      <path
        d="M87.9291 22.3116H10.0708C4.53201 22.3116 0 26.8645 0 32.4288V86.3056C0 91.8671 4.53201 96.42 10.0708 96.42H16.4037V111.409L33.4031 96.42H87.9291C93.4679 96.42 98 91.8671 98 86.3056V32.4288C98 26.8645 93.4679 22.3116 87.9291 22.3116Z"
        fill="#25836D"
      />
      <path
        d="M44.488 71.0696C44.488 70.1082 44.8457 69.3168 45.5669 68.7014C46.2851 68.0831 47.1823 67.7739 48.2582 67.7739C49.3341 67.7739 50.2311 68.0831 50.9494 68.7014C51.6706 69.3168 52.0283 70.1082 52.0283 71.0696C52.0283 72.034 51.6706 72.8224 50.9494 73.4407C50.2311 74.059 49.3341 74.3682 48.2582 74.3682C47.1823 74.3682 46.2851 74.059 45.5669 73.4407C44.8457 72.8224 44.488 72.034 44.488 71.0696ZM45.3148 65.1391C45.3148 63.2388 45.5346 61.7412 45.9802 60.6407C46.4229 59.5431 47.1412 58.5902 48.1379 57.7847C49.1318 56.9763 49.8881 56.2389 50.407 55.5696C50.923 54.9002 51.181 54.16 51.181 53.3545C51.181 51.3918 50.3074 50.4076 48.5601 50.4076C47.7539 50.4076 47.1031 50.694 46.6047 51.2669C46.1063 51.8399 45.8454 52.6142 45.819 53.5898H39C39.0293 50.9862 39.8736 48.9611 41.5417 47.5118C43.2098 46.0596 45.5493 45.3364 48.5601 45.3364C51.5592 45.3364 53.8811 46.0057 55.5287 47.3444C57.1762 48.686 58 50.5919 58 53.0623C58 54.1429 57.7772 55.1243 57.3345 56.0092C56.8889 56.8941 56.1765 57.796 55.1944 58.7206L52.8755 60.8081C52.2188 61.4179 51.7615 62.0475 51.5064 62.6914C51.2484 63.3352 51.1077 64.1521 51.0813 65.1391H45.3148Z"
        fill="white"
      />
    </svg>
  );
}

export default ErrorSystemIllustration;
