import { useQueryClient, useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { jwtDecode } from 'jwt-decode';
import { isEmpty } from 'lodash';
import axios from 'axios';
import * as RouteConstants from 'constants/routes';
import { Toast } from 'components/CustomToast';
import { useFeatureFlagState } from 'hooks/useFeatureFlag';
import service from 'libs/axios';
import { appStorage } from 'utils/storage';
import { useState } from 'react';
import { ErrorLogger } from 'utils/errorLogger';
import { Track } from 'libs/analytics/utils';
import { useTranslation } from 'react-i18next';

const DEFAULT_ERROR_TIMEOUT = 1000 * 60;
let showGlobalErrorToast = true; // used to show that a global API issue occured (like apps or insitutions failed) with a generic message.

function notOnAnErrorPage() {
  return location.pathname !== RouteConstants.UNEXPECTED_ERROR;
}
export async function fetchActivities(
  pageParam: number,
  pageSize: number
): Promise<{
  data: {
    activities: {
      id: number;
      description: string;
      type: string;
      institutionId?: number;
      institutionName?: string;
      partnerId?: string;
      partnerName?: string;
      timestamp: number;
      iconLink?: string;
    }[];
  };
}> {
  return service({
    method: 'get',
    url: `/events/latest?page=${pageParam}&pageSize=${pageSize}`,
  });
}

export function fetchTransactions(
  accountId: number,
  pageParam: number,
  pageSize: number
): Promise<{
  data: {
    institution: {
      id: number;
      name: string;
      iconLink: string;
      lastUpdatedDate: number;
      account: {
        id: string;
        name: string;
        accountNumberDisplay: string;
        balance: number;
        transactions: [];
      };
    };
  };
}> {
  return service({
    method: 'get',
    url: `/transactions/account/${accountId}?page=${pageParam}&pageSize=${pageSize}`,
  });
}

export const useVerifyMagicLink = (verifyParams?: string) => {
  const queryInfo = useQuery({
    queryKey: ['verify-magic-link', verifyParams],
    queryFn: async (): Promise<{ message: string }> => {
      const response = await service({
        method: 'get',
        url: `/customers/verify?${verifyParams}`,
      });

      return response.data;
    },
    enabled: !!verifyParams,
    gcTime: 0,
    retry: false,
  });

  return queryInfo;
};

const setSessionId = (sessionId: string) => {
  const existingSessionId = appStorage.getItem('connectPlusSessionId');
  if (sessionId && sessionId != existingSessionId) {
    ErrorLogger.setLogContext('connectPlusSessionId', sessionId);
    appStorage.setItem('connectPlusSessionId', sessionId);
    Track.identify({
      connectPlusSessionId: sessionId,
    });
  }
};

export const useAuth = (jwtToken = appStorage.getItem('JWT_TOKEN')) => {
  let status;
  let jwtData;
  let error;

  if (jwtToken) {
    const [, tokenString] = jwtToken.split(' ');

    try {
      jwtData = jwtDecode<{ id: string; aud: string; status: string }>(tokenString);
      if (jwtData) {
        appStorage.setItem('connectPlusSessionId', jwtData.id);
        setSessionId(jwtData?.id);
        if (jwtData.aud === 'RUX') {
          appStorage.setItem('user', jwtData.status);
        }
        status = 'success';
      }
    } catch (e) {
      status = 'error';
      error = e;

      console.log('Logout - useAuth', error, jwtToken);
      appStorage.removeItem('JWT_TOKEN');

      if (error) {
        status = 'expired';
      }
    }
  }

  if (!jwtToken) {
    status = undefined;
    jwtData = undefined;
    error = undefined;
  }

  return {
    status: status,
    error: error,
    isLoading: status === 'loading',
    isError: status === 'error',
    isExpired: status === 'expired',
    isSuccess: status === 'success',
    jwtData: jwtData,
  };
};

export const useGetCustomerDetails = () => {
  const { isSuccess } = useAuth();

  const queryInfo = useQuery({
    queryKey: ['customer-details'],
    queryFn: async (): Promise<
      | {
          firstName: string;
          lastName: string;
          email: string;
          isTFAEnabled: 'Y' | 'N';
          isTFAVerified: 'Y' | 'N';
          isEmailVerified?: 'Y' | 'N';
          isPhoneVerified?: 'Y' | 'N';
          phone: string;
          lockedOut: boolean;
          isNameAvailable: 'Y' | 'N';
        }
      | Record<string, never>
    > => {
      const response = await service({
        method: 'get',
        url: `/customers/details`,
      });

      return response.data;
    },
    gcTime: 0,
    enabled: isSuccess && notOnAnErrorPage(),
  });

  return {
    ...queryInfo,
    ...{ data: isSuccess ? queryInfo.data : undefined },
  };
};

export const useGetRUXCustomerDetails = () => {
  const { isSuccess } = useAuth();

  const queryInfo = useQuery({
    queryKey: ['rux-customer-details'],
    queryFn: async (): Promise<
      | {
          firstName: string;
          lastName: string;
          email: string;
          phone: string;
          userName: string;
        }
      | Record<string, never>
    > => {
      const response = await service({
        method: 'get',
        url: `/customers/rux`,
      });

      return response.data;
    },
    enabled: isSuccess,
  });

  return {
    ...queryInfo,
    ...{ data: isSuccess ? queryInfo.data : undefined },
  };
};

export const onboardCustomer = (firstName: string, lastName: string, phoneNumber: string) => {
  const createCustomer = {
    method: 'post',
    url: `/customers/details`,
    data: {
      firstName: firstName,
      lastName: lastName,
      phone: phoneNumber.replace(/[^\d]/g, ''),
    },
  };
  return service(createCustomer);
};

export const checkUserOrSave = (phoneNumber: string) => {
  const checkCustomer = {
    method: 'put',
    url: `/users/inbound-sessions`,
    data: {
      phone: phoneNumber.replace(/[^\d]/g, ''),
    },
  };
  return service(checkCustomer);
};

export const saveEmail = (email: string) => {
  const saveEmail = {
    method: 'put',
    url: `/users/inbound-details`,
    data: {
      email: email,
    },
  };
  return service(saveEmail);
};

export const useSendCode = ({ email, phoneNumber }: { email?: string; phoneNumber?: string | number }) => {
  const queryInfo = useQuery({
    queryKey: ['send-code', email || phoneNumber],
    queryFn: () => {
      return service({
        method: 'post',
        url: `/customers/sendCode`,
        data: {
          email: email,
          phone: phoneNumber,
        },
      });
    },
    gcTime: 0,
    retry: 0,
  });

  return queryInfo;
};

export const useVerifyPhoneOtp = ({ code }: { code: string }) => {
  const queryInfo = useQuery({
    queryKey: ['verify-code-phone'],
    queryFn: () => {
      return service({
        method: 'put',
        url: `/customers/verifyPhone`,
        data: {
          oneTimeCode: code,
        },
      });
    },
    enabled: false,
    gcTime: 0,
    retry: 0,
  });
  return queryInfo;
};

export const useVerifyEmailOtp = ({ code }: { code: string }) => {
  const queryInfo = useQuery({
    queryKey: ['verify-code-email'],
    queryFn: () => {
      return service({
        method: 'put',
        url: `/customers/verifyEmail`,
        data: {
          oneTimeCode: code,
        },
      });
    },
    enabled: false,
    gcTime: 0,
    retry: 0,
  });
  return queryInfo;
};

export const useGetUserLoggedInStatus = () => {
  const { data: userDetails } = useGetCustomerDetails();

  if (!userDetails) return false;
  if (userDetails && !Object.keys(userDetails)?.length) return false;
  if (userDetails.isTFAEnabled === 'Y' && userDetails.isTFAVerified === 'N') return false;
  //if (readCookie('flow') === 'rux') return false;
  if (appStorage.getItem('flow') === 'rux') return false;

  return true;
};

let accountsErrorOccurred = false;
export const useGetAccounts = () => {
  const { t } = useTranslation();
  const isUserLoggedIn = useGetUserLoggedInStatus();

  const queryInfo = useQuery({
    queryKey: ['accounts'],
    queryFn: async (): Promise<{
      customerId: number;
      institutions?: { id: number; name: string; tileLink?: string; iconLink?: string; isNew: boolean; status: string }[];
    }> => {
      const response = await service({
        method: 'get',
        url: `/institutions`,
      });
      if (response?.data?.customerId) {
        ErrorLogger.setLogContext('customerId', response?.data?.customerId);
      }

      return response.data;
    },
    enabled: isUserLoggedIn && !accountsErrorOccurred,
  });

  if (queryInfo.isError) {
    if (showGlobalErrorToast) {
      Toast.error({
        title: t('common.errors.error-went-wrong'),
        message: t('common.errors.error-fetching-data'),
      });
    }

    accountsErrorOccurred = true;
    showGlobalErrorToast = false;
    setTimeout(() => {
      accountsErrorOccurred = false; // Will refetch after timeout.
    }, DEFAULT_ERROR_TIMEOUT);
  }

  return queryInfo;
};

export type App = {
  customerId: number;
  partnerId: number;
  partnerName: string;
  iconLink: string;
  status: string;
  isNew: boolean;
};

export const useGetAccountDetails = (institutionId?: string) => {
  const isUserLoggedIn = useGetUserLoggedInStatus();

  const queryInfo = useQuery({
    queryKey: ['account-details', institutionId],
    queryFn: async (): Promise<{
      apps: [];
      customerId: number;
      institutions?: {
        id: number;
        name: string;
        tileLink?: string;
        isNew: boolean;
        iconLink: string;
        accountTypeDescription: string;
        loginId: number;
        status: 'string';
        accounts: { id: string; name: string; balance: number; accountNumberDisplay: string }[];
        lastUpdatedDate: number;
        apps: App[];
      }[];
    }> => {
      const response = await service({
        method: 'get',
        url: `/institutions/${institutionId}`,
      });
      return response.data;
    },
    enabled: isUserLoggedIn && !!institutionId && notOnAnErrorPage(),
  });

  return queryInfo;
};

let appsErrorOccurred = false;
export const useGetApps = () => {
  const { t } = useTranslation();
  const isUserLoggedIn = useGetUserLoggedInStatus();
  const { features } = useFeatureFlagState();
  const market = features?.MARKET;

  const queryInfo = useQuery({
    queryKey: ['apps'],
    queryFn: async (): Promise<{
      apps: { id: string; customerId: number; partnerId: string; partnerName?: string; isNew: boolean; name: string; iconLink?: string }[];
    }> => {
      const response = await service({
        method: 'get',
        url: `/apps`,
      });

      return response.data;
    },
    enabled: isUserLoggedIn && market !== 'AUS' && !appsErrorOccurred,
    gcTime: 0,
  });

  if (queryInfo.isError) {
    if (showGlobalErrorToast) {
      Toast.error({
        title: t('common.errors.error-went-wrong'),
        message: t('common.errors.error-fetching-data'),
      });
    }

    appsErrorOccurred = true;
    showGlobalErrorToast = false;
    setTimeout(() => {
      appsErrorOccurred = false; // Will refetch after timeout.
    }, DEFAULT_ERROR_TIMEOUT);
  }

  return {
    ...queryInfo,
    data: queryInfo.data?.apps,
  };
};

export const useGetActivities = () => {
  const isUserLoggedIn = useGetUserLoggedInStatus();

  const queryInfo = useQuery({
    queryKey: ['activities'],
    queryFn: async () => {
      const response = await fetchActivities(0, 5);

      return response.data;
    },
    enabled: isUserLoggedIn,
    gcTime: 0,
  });

  return {
    ...queryInfo,
    data: queryInfo.data?.activities,
  };
};

export const useGetInfiniteActivities = (pageSize = 20) => {
  const isUserLoggedIn = useGetUserLoggedInStatus();

  const queryInfo = useInfiniteQuery({
    initialPageParam: 0,
    queryKey: ['infinite-activities', pageSize],
    queryFn: async (params) => {
      const { pageParam = 0 } = params;
      const response = await fetchActivities(pageParam, pageSize);
      return response.data;
    },
    enabled: isUserLoggedIn,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.activities.length === pageSize ? allPages.length : undefined;
    },
  });

  return queryInfo;
};

export const useInvalidateData = () => {
  const queryClient = useQueryClient();

  const invalidateData = () => {
    queryClient.invalidateQueries(['accounts']);
    queryClient.invalidateQueries(['account-details']);
    queryClient.invalidateQueries(['apps']);
    queryClient.invalidateQueries(['activities']);
    queryClient.invalidateQueries(['infinite-activities']);
  };

  return {
    invalidateData,
  };
};

export const useGetUserStatus = () => {
  const { status, isLoading: authLoading, isSuccess: isMagicLinkVerified, isExpired: isMagicLinkExpired, isError: authError } = useAuth();
  const { isInitialLoading: customerDetailsLoading, data: userDetails, isError: CustomerDetailsError } = useGetCustomerDetails();
  const { isInitialLoading: accountsLoading, data: accountsData } = useGetAccounts();
  const isUserOnboarded = userDetails && userDetails?.isNameAvailable === 'Y';
  const isUserHalfOnboarded =
    userDetails && Object.keys(userDetails).includes('phone') && userDetails?.isNameAvailable === 'N' && userDetails?.isEmailVerified === 'Y';
  const isTFAEnabled = userDetails && userDetails?.isTFAEnabled === 'Y';
  const isTFAVerified = userDetails && userDetails?.isTFAVerified === 'Y';
  const isUserVerified = isUserOnboarded ? (isTFAEnabled ? isTFAVerified : true) : false;
  const allFeatures = useFeatureFlagState();
  const market = allFeatures.features?.MARKET;
  const connectPlusSessionId = appStorage.getItem('connectPlusSessionId');

  let navigateTo;
  let authStatus;
  const isLoading = authLoading || customerDetailsLoading || accountsLoading;
  const downstreamErrorOccurred = authError || CustomerDetailsError;

  if (!downstreamErrorOccurred) {
    if (status === undefined) {
      authStatus = 'PUBLIC';
    }

    if (isMagicLinkVerified) {
      authStatus = 'PROTECTED';
    }

    if (isMagicLinkVerified && !customerDetailsLoading && (!isUserOnboarded || isUserHalfOnboarded) && market !== 'AUS') {
      navigateTo = RouteConstants.RUX_MORE_DETAILS;
      authStatus = 'PROTECTED';
    }

    if (isMagicLinkVerified && !customerDetailsLoading && !isUserOnboarded && market === 'AUS') {
      navigateTo = RouteConstants.ONBOARDING_TERMS_CONDITIONS;
      authStatus = 'PROTECTED';
    }

    if (isMagicLinkVerified && !customerDetailsLoading && isUserOnboarded && !isUserVerified) {
      navigateTo = '/verify-otp';
      authStatus = 'PROTECTED';
    }

    if (isMagicLinkVerified && !customerDetailsLoading && isUserVerified && !accountsLoading) {
      navigateTo = isEmpty(accountsData?.institutions) ? RouteConstants.ACCOUNTS : RouteConstants.HOME;
      authStatus = 'PRIVATE';
    }
  } else {
    //  Downstream error occurred but user still has active session.
    if (connectPlusSessionId) {
      authStatus = 'PROTECTED';
      navigateTo = RouteConstants.UNEXPECTED_ERROR;
    } else {
      //  Downstream error occurred and user no longer has active session.
      authStatus = 'PUBLIC';
      navigateTo = undefined;
    }
  }

  return {
    isLoading: isLoading,
    isMagicLinkVerified: isMagicLinkVerified,
    isMagicLinkExpired: isMagicLinkExpired,
    isUserVerified: isUserVerified,
    authStatus: authStatus,
    navigateTo: navigateTo,
    isError: downstreamErrorOccurred,
  };
};

export const useGetInfiniteTransactions = (accountId, pageSize = 25) => {
  const isUserLoggedIn = useGetUserLoggedInStatus();

  const queryInfo = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ['infinite-transactions', accountId, pageSize],
    queryFn: async (params) => {
      const { pageParam = 0 } = params;

      const response = await fetchTransactions(accountId, pageParam, pageSize);

      return response.data;
    },
    enabled: isUserLoggedIn,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.institution.account.transactions.length === pageSize ? allPages.length : undefined;
    },
  });

  return queryInfo;
};

export const useGetTermsAndConditions = () => {
  const queryInfo = useQuery({
    queryKey: ['terms-and-policies'],
    queryFn: async (): Promise<{ termsAndConditionsUrl: string; privacyPolicyUrl: string; termsAndConditionsContent?: string; tcContent?: string }> => {
      const response = await service({
        method: 'get',
        url: `/consent/terms-and-policies`,
      });
      const { data } = response;

      if (data.termsAndConditionsUrl) {
        try {
          const { data: markdownResponse } = await axios.get(data.termsAndConditionsUrl);

          return { ...data, termsAndConditionsContent: markdownResponse };
        } catch (err) {
          console.log('Error', err);
        }

        return data;
      }

      return data;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });

  return queryInfo;
};

export const useGetSupportReasons = () => {
  const queryInfo = useQuery({
    queryKey: ['support-reasons'],
    queryFn: async (): Promise<{ reasons: { code: number; description: 'string' }[] }> => {
      const response = await service({
        method: 'get',
        url: `/tickets/reasons`,
      });

      return response.data;
    },

    select: (data) => {
      const { reasons } = data;

      if (reasons) {
        return reasons.reduce((acc: { id: number | string; label: string; value: number | string }[], item) => {
          acc.push({
            id: item.code,
            label: item.description,
            value: item.code,
          });

          return acc;
        }, []);
      }

      // Note: Hardcoded this in case the API fails or returns empty
      return [
        {
          id: 4,
          label: 'Other',
          value: 4,
        },
      ];
    },
  });

  return queryInfo;
};

export const useGetSettingConnection = () => {
  const [data, setData] = useState({
    addAccountEnabled: 'N',
    deleteAccountEnabled: 'N',
  });

  const { features } = useFeatureFlagState();
  const market = features?.MARKET;
  const { t } = useTranslation();

  const { data: res } = useQuery({
    queryKey: ['setting-connection'],
    queryFn: async (): Promise<{ addAccountEnabled: 'Y'; deleteAccountEnabled: 'Y' } | null> => {
      try {
        const response = await service({
          method: 'get',
          url: `/notifications/settings`,
          overrideErrorInterceptor: true,
        });
        setData((_) => ({ ...(_ || {}), ...(response.data || {}) }));
        return response.data;
      } catch (error) {
        Toast.error({
          title: t('common.errors.error-went-wrong'),
          message: t('common.errors.error-went-wrong-description'),
        });
      }
      return null;
    },
    enabled: market !== 'AUS',
    gcTime: 0,
  });

  const updateConnectionSetting = async (param: { addAccountEnabled?: 'Y' | 'N'; deleteAccountEnabled?: 'Y' | 'N' }) => {
    try {
      setData((_) => ({ ...(_ || {}), ...(param || {}) }));

      const response = await service({
        method: 'put',
        url: `/notifications/settings`,
        data: param,
      });
      setData((_) => ({ ...(_ || {}), ...(response.data || {}) }));

      return response.data;
    } catch (error) {
      Toast.error({
        title: t('common.errors.error-went-wrong'),
        message: t('common.errors.error-went-wrong-description'),
      });
    }
  };

  return {
    data,
    updateConnectionSetting,
  };
};

export function useGetAppDetails(customerId?: string, partnerId?: string | null) {
  const { isLoading, data, isError } = useQuery({
    queryKey: ['apps-data', partnerId],
    queryFn: async () => {
      const response = await service({
        method: 'get',
        url: `/apps/customers/${customerId}?partnerId=${partnerId}`,
      });
      return response.data;
    },
  });
  return { isLoading, data, isError };
}
