import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Loading } from 'components/Loading';
import service from 'libs/axios';
import { appStorage } from 'utils/storage';
import { useWindowSize } from '../../hooks/useWindowSize';
import { ErrorLogger } from 'utils/errorLogger';
import { useInvalidateData } from 'services';

function removeStorage() {
  appStorage.removeItem('connectPlusSessionId');
  appStorage.removeItem('JWT_TOKEN');
  appStorage.removeItem('flow');
  ErrorLogger.reset();
  //deleteCookie('flow');
}

function Logout() {
  const navigate = useNavigate();
  const screen = useWindowSize();
  useInvalidateData();
  const { isLoading } = useQuery({
    queryKey: ['logout'],
    queryFn: async () => {
      const response = await service({
        method: 'delete',
        url: `/customers/logout`,
      });

      return response.data;
    },
    gcTime: 0,
  });

  useEffect(() => {
    if (!isLoading) {
      removeStorage();
      navigate('/sign-in');
    }
  }, [isLoading]);

  return <Loading show={isLoading} message={screen.screenSize == 'sm' ? 'Signing out...' : 'Securely signing you out...'} />;
}

export default Logout;
export { removeStorage };
