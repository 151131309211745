import { ReactNode, useState, useLayoutEffect } from 'react';

import Analytics, { AnalyticsInstance } from 'analytics';
import { AnalyticsProvider as Provider, useAnalytics } from 'use-analytics';

import fullStoryPlugin from '@analytics/fullstory';
import { useFeatureFlagState } from 'hooks/useFeatureFlag';
import { Track } from './utils';
import { ErrorLogger } from 'utils/errorLogger';
import { appStorage } from 'utils/storage';
import { STANDALONE_ANALYTICS_EVENTS } from './constants';

function AnalyticsProvider({ children }: { children: ReactNode }) {
  const [analytics, setAnalytics] = useState<AnalyticsInstance | null>(null);
  const { configIsLoaded, configLoadingError, features } = useFeatureFlagState();
  const ENV = features?.ENV;

  useLayoutEffect(() => {
    if (configIsLoaded || configLoadingError) {
      try {
        const plugins =
          ENV === 'production'
            ? [
                fullStoryPlugin({
                  org: 'o-19GD0V-na1',
                  namespace: 'FullStory-ConnectPlus',
                }) as Record<string, unknown>,
              ]
            : [];
        const _analytics = Analytics({
          app: 'ConnectPlus',
          plugins,
        });

        appStorage.setItem('market', features?.MARKET);

        Track.init(_analytics);
        const userDetails = _analytics.user();
        ErrorLogger.setLogContext('fullStoryUserId', userDetails?.userId || '');

        const flow = appStorage.getItem('flow');
        if (flow !== 'rux') {
          Track.event(STANDALONE_ANALYTICS_EVENTS.APP_INITIALIZED);
        }
        setAnalytics(_analytics);
      } catch (e) {
        console.error('Analytics failed to load');
      }
    }
  }, [configIsLoaded, configLoadingError]);

  if (!analytics) {
    return <>{children}</>;
  }

  return <Provider instance={analytics}>{children}</Provider>;
}
export { AnalyticsProvider, useAnalytics };
