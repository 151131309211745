import { useTranslation } from 'react-i18next';

import { Typography } from 'components/Typography';
import { Link } from 'components/Link';

import * as RouteConstants from 'constants/routes';

import ErrorSystemIllustration from 'icons/ErrorSystemIllustration';

function ErrorScreen() {
  const { t } = useTranslation();

  return (
    <div className="mx-auto flex h-full max-w-[390px] flex-1 flex-col self-center p-6 pt-0 sm:pt-6">
      <Typography variant="heading" component="h1" className="pb-3 text-left">
        {t('common.errors.error-title')}
      </Typography>

      <Typography variant="subheading" component="p" className="pb-10 text-left text-[#555250]">
        {t('common.errors.error-description')}
      </Typography>

      <div className="flex flex-1 justify-center pb-3 pt-3 sm:flex-none">
        <ErrorSystemIllustration />
      </div>

      <div className="flex w-full flex-col justify-end pb-10 pt-4">
        <Link to={RouteConstants.LANDING} variant="contained" color="primary" className="flex">
          {t('common.errors.back-to-home')}
        </Link>
      </div>
    </div>
  );
}

export default ErrorScreen;
