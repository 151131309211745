import ReactFocusLock from 'react-focus-lock';
import { ReactFocusLockProps } from 'react-focus-lock/interfaces';

const FocusTrap: React.FC<ReactFocusLockProps> = (props) => {
  return <ReactFocusLock returnFocus={true} autoFocus={false} className="flex h-full w-full flex-1 flex-col" {...props} />;
};

export { FocusTrap };

// import React from 'react';
// import FocusTrap from 'focus-trap-react';

// interface Props {
//   children: React.ReactNode;
// }

// const FocusTrap: React.FC<Props> = ({ children }) => {
//   return (
//     <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>
//       <div className="flex flex-col flex-1">
//         { children }
//       </div>
//     </FocusTrap>
//   );
// };

// export { FocusTrap };

// const useTabFocus = (initialFocusSelector?: string) => {
//   const [focusableElements, setFocusableElements] = useState<HTMLElement[]>([]);

//   useEffect(() => {
//     const setTabFocus = (event: KeyboardEvent) => {
//       if (!focusableElements.length) return;
//       const currentFocus = document.activeElement as HTMLElement;
//       const focusIndex = focusableElements.indexOf(currentFocus || 0);

//       console.log(currentFocus, focusIndex, focusableElements)

//       if (event.key === 'Tab') {
//         event.preventDefault();
//         if (event.shiftKey) {
//           if (focusIndex === 0) {
//             focusableElements[focusableElements.length - 1].focus();
//           } else {
//             focusableElements[focusIndex - 1].focus();
//           }
//         } else if (focusIndex === focusableElements.length - 1) {
//           focusableElements[0].focus();
//         } else {
//           focusableElements[focusIndex + 1].focus();
//         }
//       }
//     };
//     document.addEventListener('keydown', setTabFocus);
//     return () => document.removeEventListener('keydown', setTabFocus);
//   }, [focusableElements]);

//   useEffect(() => {
//     const focusableNodeList = document.querySelectorAll(
//       'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
//     );
//     const focusableElements = Array.from(focusableNodeList) as HTMLElement[];
//     setFocusableElements(focusableElements.filter((element) => element.offsetParent !== null));

//     if (initialFocusSelector) {
//       const initialFocusElement = document.querySelector(initialFocusSelector) as HTMLElement;
//       if (initialFocusElement) {
//         initialFocusElement.focus();
//       }
//     }
//   }, [initialFocusSelector]);
// };
