import { ReactNode, Suspense, useEffect } from 'react';
import { Loading } from 'components/Loading';
import { useFeatureFlagState } from 'hooks/useFeatureFlag';

type SetMarketProps = {
  children: ReactNode;
};

const SetMarket = ({ children }: SetMarketProps) => {
  const { fetchConfig, configIsLoaded } = useFeatureFlagState();

  useEffect(() => {
    if (!configIsLoaded) {
      fetchConfig();
    }
  }, [configIsLoaded]);

  return <Suspense fallback={<Loading show={true} />}>{children}</Suspense>;
};
export default SetMarket;
