import IconProps from './IconProps';

function InfoFilled(props: IconProps) {
  const { width = 24, height = 24, fill = '#CF4500', ariaLabel } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label={ariaLabel}>
      <circle cx="12" cy="12" r="12" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.25 13.5188H10.3625V5.125H13.25V13.5188ZM11.8688 18.875C10.9057 18.875 10.125 18.0943 10.125 17.1313C10.125 16.1682 10.9057 15.3875 11.8688 15.3875C12.8318 15.3875 13.6125 16.1682 13.6125 17.1313C13.6091 18.0929 12.8304 18.8716 11.8688 18.875Z"
        fill="white"
      />
    </svg>
  );
}

export default InfoFilled;
